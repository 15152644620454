.sys-guard {
  &.guard-content {
    background: #f7f8fc
  }

  .sys-guard-home-page-container {
    display: flex;
    margin-left: 186px;
    height: 917px;
  }

  .sys-guard-header-container {
    height: 95px;
    font-family: Nunito-Regular;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 240px;
    background: linear-gradient(rgba(241, 242, 246, 0), #dfe1e9);
  }

  .sysGuardHeaderTitle {
    margin: 0px;
    font-size: 32px;
    color: #8000bd;

    &.content {
      cursor: pointer;
    }
  }

  .sys-guard-dashboard-header-container {
    margin-top: 10px;
    margin-left: 15px;
    width: 85vw;
    height: 150px;
    border-radius: 15px;
    box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.6);
    background: linear-gradient(to bottom right, #521c72, #7243ac);
  }

  .MuiButtonBase-root {
    color: white;
    background: #b128f1;
    width: 100px;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 600;

    &:hover {
      background-color: #a601f3;
    }
  }

  .sys-guard-connection-status-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-right: 30px;
  }

  .sys-guard-connection-text {
    color: #8000bd;
    font-weight: 600;
  }

  .sys-guard-status {
    margin-top: 6px;
    cursor: pointer;
    margin-right: 10px;
  }
}

.sys-guard.MuiButtonBase-root {
  color: white;
  background: #b128f1;
  margin-right: 10px;
  text-transform: none;
  margin-left: 12px;
  margin-top: 10px;
  width: 140px;

  .MuiButton-label {
    white-space: nowrap;
  }

  &:hover {
    background-color: #a601f3;
  }
}

.sysGuardDrawer {
  background-color: white;
}

.onBoardFormContainer {
  width: 650px;
  padding: 30px 30px;
  box-sizing: border-box;

  .dataSourceTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dataSourceTitle {
    height: 24px;
    width: 300px;
    color: #292929;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 1rem;
    white-space: noWrap;
  }
}

$primary-color: #b128f1;
$error-color: #ff4d4d;

.form-group-container {
  padding-top: 10px;

  .MuiButtonBase-root {
    color: white;
    background: #b128f1;
    width: 100px;
    text-transform: capitalize;
    font-size: 15px;

    &:hover {
      background-color: #a601f3;
    }
  }

  .form-group {
    margin-bottom: 16px;

    &.application {
      padding: 10px 0px;
    }

    label {
      font-weight: 500;
      display: block;
      margin-bottom: 4px;
    }

    .required {
      color: $error-color;
    }

    .MuiFormControl-root {
      width: 100%;
    }

    .MuiTextField-root {
      width: 100%;
    }

    .error-text {
      color: $error-color;
      font-size: 12px;
      margin-top: 4px;
    }
  }
}

.sys-guard-radial-progress-bar-container {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-left: 3%;
  padding-right: 3%;
  justify-content: space-between;

  &.sys-guard-content {
    display: flex;
    align-items: center;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    gap: 0px;
    justify-content: space-between;
  }

  .sys-guard-text-container {
    display: flex;
    align-items: center;
  }

  .sys-guard-radial-text {
    font-size: 12px;
    color: white;

    &.content {
      color: black;
    }
  }

  .sys-guard-text-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .sys-guard-radial-progress {
    width: 70px;
    height: 70px;
    margin-left: 15px;
  }

  .sys-guard-text-container img {
    width: 24px;
    height: 24px;
    margin-right: 2px;
  }

  .CircularProgressbar {
    width: max-content !important;
  }

  .circular-progress-bar-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text-sm {
    font-size: 11px;
    color: #a09c9c;
    font-weight: 600;
  }

  .text-l {
    font-size: 18px;
    color: white;
    font-weight: 600;
  }
}

.content {
  font-size: 18px;
  color: white;
  font-weight: 600;
}

.vertical-card-parent-container {
  display: flex;
  margin-left: 85px;
  gap: 100px;
  align-items: center;

  .icon-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .icon-text {
    color: #b5abb1;
    font-size: 20px;
    font-weight: 600;
  }
}

.sys-guard-dashboard-main-container {
  display: flex;
  flex-direction: column;
  gap: 5%;
  width: 1250px;

  .sys-guard-card-container {
    width: 450px;
    height: 600px;
    border-radius: 15px;
    cursor: pointer;

    &.location {
      background: radial-gradient(circle at bottom right, #808457 20%, #531d72 60%);
    }

    &.users {
      background: radial-gradient(circle at bottom right, #9e204d 20%, #531d72 60%);
    }

    &.applications {
      background: radial-gradient(circle at bottom right, #986763 20%, #531d72 60%);
    }
  }

  .sys-guard-vertical-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .no-of-locations {
      color: white;
      font-size: 18px;

      .number-text {
        color: #8dc63f;
        padding-left: 5px;
      }

      .location-text {
        color: #f43860;
        padding-left: 5px;
      }
    }
    .sys-guard-vertical-card {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

// Approx 80% zoom level
@media (min-width: 1600px) {

  /* Increasing specificity by adding body or parent selectors */
  body .sys-guard .sys-guard-dashboard-header-container {
    margin-left: 20px;
    width: 87vw;
  }

  body .sys-guard-dashboard-main-container {
    width: 85vw;
  }

  body .vertical-card-parent-container {
    gap: 100px;
  }


  body .sys-guard-radial-progress-bar-container .sys-guard-radial-text {
    font-size: 16px;
  }

  body .sys-guard-radial-progress-bar-container .sys-guard-radial-progress {
    width: 80px;
    height: 80px;
  }
}


@media (min-width: 1800px) {
  body .vertical-card-parent-container {
    gap: 100px;
  }
}

//67% zoom level
@media (max-width: 2400px) {
  body .sys-guard-content-page-container .sys-guard-content-header-container {
    width: 1740px;
  }
}

//75% zoom level
@media (max-width: 2050px) {
  body .sys-guard-content-page-container .sys-guard-content-header-container {
    width: 1490px;
  }
}

//80% zoom level
@media (max-width: 2015px) {
  body .sys-guard-content-page-container .sys-guard-content-header-container {
    width: 1360px;
  }
}

//90% zoom level
@media (max-width: 1800px) {
  body .sys-guard-content-page-container .sys-guard-content-header-container {
    width: 1150px;
  }
}

//100% zoom level
@media (max-width: 1600px) {
  body .sys-guard-content-page-container .sys-guard-content-header-container {
    width: 982px;
  }
}

@media (min-width: 2500px) {
  body .sys-guard .sys-guard-dashboard-header-container {
    margin-left: 20px;
    width: 91vw;
  }

  body .vertical-card-parent-container {
    gap: 590px;
  }

  body .sys-guard-content-page-container .sys-guard-content-header-container {
    width: 2500px;
  }
}

.sys-guard-gauge-container {
  padding-top: 5px;
}

.sys-guard-card {
  width: 82%;
  padding: 15px;
  border-radius: 20px;
  /* Rounded corners */
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.2),
    /* Inner glow */
    0 4px 10px rgba(0, 0, 0, 0.3);
  /* Outer shadow */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  /* Space below image */
  &.location {
    background: linear-gradient(to bottom right, #531d72, #808457);
    /* Gradient */
  }

  &.users {
    background: linear-gradient(to bottom right, #531d72, #9e204d);
    /* Gradient */
    height: 175px;
  }

  &.applications {
    background: linear-gradient(to bottom right, #531d72, #986763);
    /* Gradient */
  }
}

.sys-guard-card-content {
  background: #aad3df;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.sys-guard-map-container {
  background: #aad3df;
  width: 100%;
  height: 175px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  .leaflet-container {
    background: none;
  }

  .leaflet-touch .leaflet-control-attribution {
    display: none;
  }

  &.location {
    height: 350px;
  }
  &.dashboard {
    width: 1300px;
    height: 700px
  }
}

.longMap {
  height: 600px;
  width: 100%;
}

.sys-guard-map {
  background-color: #aad3df;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  &.dashboard {
    width: 1300px;
    height: 700px;
    background-color: #d5e8eb;
  }
}

.chart-container-sysguard {
  width: 91%;
  background: white;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 0px;
}

.chart-title {
  font-size: 16px;
  font-weight: bold;
  color: #444;
  margin-bottom: 10px;
  text-align: left;
}

.custom-tooltip {
  background: rgba(255, 255, 255, 0.9);
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 12px;
  color: #333;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.custom-bar:hover {
  fill-opacity: 1 !important;
}

.sys-guard-content-page-container {
  display: flex;
  gap: 40px;
  margin-left: 215px;
  margin-top: 20px;

  .sys-guard-content-header-container {
    width: 979px;
  }
}

.sidebar-container {
  background: #3b0d5e;
  padding: 15px;
  border-radius: 12px;
  width: 190px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 145px;

  .menu-item {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 170px;
    padding: 5px;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;

    &.Locations {
      background: linear-gradient(to left, #72675f, #3b0d5e);

      &:hover,
      &.active {
        border: 2px solid #4cc9f0;
      }
    }

    &.Users {
      background: linear-gradient(to left, #7f2f68, #3b0d5e);

      &:hover,
      &.active {
        border: 2px solid #4cc9f0;
      }
    }

    &.Applications {
      background: linear-gradient(to left, #8a5566, #3b0d5e);

      &:hover,
      &.active {
        border: 2px solid #4cc9f0;
      }
    }
  }
}

.sys-guard-content-page {
  background: linear-gradient(to bottom right, #521c72, #7243ac);
  border-radius: 15px;
  padding: 20px;
  height: 137px;
  display: flex;
  align-items: center;

  .summary-text {
    font-size: 20px;
    font-weight: 600;
  }
}

.tableContainer {
  background: white;
  border-radius: 8px;
  padding: 10px;
  margin-left: 200px;
  margin-right: 20px;
  background: #f7f8fc;
}

.dataGrid {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-top: 20px;
  max-height: 625px;
  overflow: hidden;

  .MuiDataGrid-columnHeaders {
    background: #6a0dad;
    color: white;
  }

  .MuiDataGrid-virtualScroller {
    overflow-y: auto !important;
    overflow-x: hidden;
    max-height: 625px;
  }

  .MuiDataGrid-cell {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.sys-guard-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Black with 50% transparency */
  backdrop-filter: blur(3px); /* Adds the blur effect */
  z-index: 999; /* Ensure it overlays all other content */
}

.greenText {
  color: white;
  background: #8dc63f;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  min-width: 50px;
  align-items: center;
  justify-content: center;
}

.redText {
  color: white;
  background: #dd5434;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  min-width: 50px;
  align-items: center;
  justify-content: center;
}

.risk-High {
  background: red;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.risk-Medium {
  background: orange;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.risk-Low {
  background: gray;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.sys-guard-location {
  color: white;
  border-radius: 5px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 40px;
  max-width: fit-content;

  &.high {
    background: #dd5434;
  }

  &.normal {
    background: #f6bd41;
  }

  &.green {
    background: #8dc63f;
  }

  &.highRisk {
    background: #dd5434;
    min-width: 60px;
    padding: 5px;
  }

  &.mediumRisk {
    background: #f6bd41;
    min-width: 60px;
    padding: 5px;
  }

  &.lowRisk {
    background: #1282b3;
    min-width: 60px;
    padding: 5px;
  }

  &.nonAnomalies {
    color: black;
    background-color: white;
    border: 1px solid black;
  }

  &.none {
    background: #8dc63f;
    min-width: 60px;
    padding: 5px;
  }

  &.pointer {
    cursor: pointer;
  }
}

.sys-guard-data-table {
  background: #f7f8fc;
  height: 725px;
}

.sysGuardLoader {
  position: absolute;
  top: 46%;
  margin-left: 52%;
  z-index: 999;
  &.popup {
    margin-left: 50%;
  }
}

.timeSelectorContainer {
  display: flex;
  gap: 15px;
  font-size: 18px;
  align-items: center;
  margin-right: 35px;
}

.heatMapContainer {
  width: 100%;
  font-family: "sans-serif";

  .heatmap-tooltip {
    cursor: pointer;
    color: black;
    .heatmap-number{
      font-size: 20px;
    }
  }
}

.user-risk-container {
  display: flex;
  gap: 10px;
}

.user-risk-circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;

  .user-risk-circle-text {
    font-size: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.high {
    background-color: #dd5434;
  }

  &.medium {
    background-color: #f6bd41;
  }

  &.low {
    background-color: #1282b3;
  }

  &.none {
    background-color: gray;
  }
}

.country-badge {
  color: black;
  width: fit-content;
  padding: 2px 10px;
  border-radius: 10%;
  font-weight: bold;
  font-size: 13px;
}

.user-location-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.sys-guard-popup-table {

  // For WebKit-based browsers (Chrome, Safari, Edge)
  .MuiDataGrid-virtualScroller {
    &::-webkit-scrollbar {
      width: 6px; // Thin scrollbar
    }

    &::-webkit-scrollbar-track {
      background: transparent; // Optional: Transparent track
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3); // Subtle scrollbar color
      border-radius: 3px;
    }

    &::-webkit-scrollbar-button {
      display: none; // Removes top and bottom scrollbar buttons
    }
  }

  // For Firefox
  scrollbar-width: thin;

}

.sysguard-popup-container {
  display: flex;
  position: relative;
  background: #fff;
  border-radius: 10px;
  padding: 40px;
  left: 30px;

  .user-location-left-panel {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    flex-direction: column;
    gap: 5px;

    .title {
      font-size: 14px;
      white-space: nowrap;
    }

    .second-title-container {
      display: flex;
      align-items: center;
      gap: 10px;

      .second-title {
        font-size: 20px;
        font-weight: 600;
        white-space: nowrap;
      }
    }

  }

  .header-title {
    font-size: 16px;
    font-weight: 600;
  }

  .popup-title {
    font-size: 24px;
    font-weight: 700;
  }
}

.sys-guard-popup-close-button {
  position: absolute;
  top: 5px;
  right: 10px;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  color: gray;
}

.side-popup-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.side-popup-item {
  display: flex;
  flex-direction: column;
}

.side-popup-name {
  color: #666;
  margin-bottom: 4px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 210px;
  text-overflow: ellipsis;
}

.progress-bar {
  width: 100%;
  height: 8px;
  background: rgba(255, 182, 193, 0.5); // Light pink background
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.progress-fill {
  height: 100%;
  background: linear-gradient(to right, orange, red); // Gradient effect
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
}

.second-container {
  display: flex;
  gap: 20px;
  &.application {
    gap: 10px;
  }
}

.right-side-panel {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 309px;
  padding-left: 15px;
}

.secondary-title {
  font-size: 14px;
  font-weight: 700;
}

.device-chart-container {
  display: flex;
  flex-direction: column;
  gap: 35px;
  align-items: center;

  h4 {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin: 0;
  }
}

.device-legend {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.device-legend-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #333;

  .device-name {
    text-align: left;
    width: 100px;
    white-space: nowrap;
  }

  .device-count {
    font-weight: bold;
    margin-right: 8px;
  }

  .device-percentage {
    color: #666;
  }
}

.sys-guard-location-side-chart {
  display: flex;
  flex-direction: column;
  gap: 170px;
  width: 500px;
  &.application {
    gap: 255px;
  }
  &.location {
    gap: 155px;
  }
}

.sys-guard-table-cell{
  color: white;
}

.country-names-container {
  display: flex;
  flex-wrap: wrap;
}

.country-badge {
  text-align: center;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.country-flag {
  width: 30px;
  height: 20px;
  display: block;
  border-radius: 1px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.country-code {
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
  color: #333;
}

.country-with-flag {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.sys-guard-map-parent-container {
  .leaflet-touch .leaflet-control-attribution {
    display: none;
  }
}

.sys-guard-country-map-container {
  width: 100%;
  height: 600px;
  border-radius: 10px;
}

.sys-guard-table-pop-up {
  width: 600px;
  &.locationAnomaly {
    width: 580px;
  }
  .highlight-row {
    background-color: #fedad1;
  }
}

.sys-guard-line-chart-container {
  width: 530px;
}

.sys-guard-info-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-top: 0px;
  background-color: white;
  box-shadow: 0px 4px 10px #dd5434;
  border-radius: 10px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 500px;
  border: 1px solid #dd5434;

  .parent-container {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    gap: 10px;
  }

  .para-name {
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
  }

  .child-container {
      display: flex;
      align-items: baseline;
      gap: 20px;
    }
  .child-container-two {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1f2937;
  }

  p {
    color: #4b5563;
    margin-top: 8px;
  }
}
.sys-guard-toggle-label{
  white-space: nowrap;
}

.sys-guard-incognito-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  .MuiButtonBase-root {
    background: none;
    width: auto;

    &:hover {
      background-color: transparent;
    }
  }
  .MuiFormControlLabel-root {
    margin-right: 0%;
  }
  &.overViewScreen {
    margin-right: 30px;
  }
}

.sys-guard-dashboard-popup-container {
  display: flex;
  position: relative;
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  left: 30px;
  width: 1300px;
  height: 700px;
  animation: fadeInPopup 0.3s ease-in-out;
}

@keyframes fadeInPopup {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}