.main-dashboard-container {
  padding: 0px 23px 0px 211px;
  display: flex;
  .google-visualization-tooltip { pointer-events: none; }
  .first-row,
  .second-row,
  .third-row,
  .sensitiveGroupWidget,
  .medium-row {
    .MuiCardHeader-root {
      padding-bottom: 5px;
    }
    .MuiCardContent-root {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  &.switching {
    flex-direction: column;   
   }

  .mdrIncidentFirstRow,
  .mdrPatchMonitoringFirstRow,
  .mdrEndPointsFirstRow,
  .mdrEndPointsSecondRow,
  .mdrAutheticationFirstRow,
  .mdrSecondRowAuthentication
  {
    .MuiCardHeader-root {
      padding-bottom: 5px;
    }
    .MuiCardContent-root {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  // .mdrPatchMonitoringFirstRow{
  //   .MuiCardHeader-root {
  //     padding-bottom: 5px;
  //   }
  //   .MuiCardContent-root {
  //     padding-top: 5px;
  //     padding-bottom: 5px;
  //   }
  // }
  // .mdrEndPointsFirstRow{
  //   .MuiCardHeader-root {
  //     padding-bottom: 5px;
  //   }
  //   .MuiCardContent-root {
  //     padding-top: 5px;
  //     padding-right: 0px;
  //     padding-bottom: 5px;
  //   }
  // }


  .first-row {
    .MuiCard-root {
      height: 200px;
    }
  }

  .second-row {
    .MuiCard-root {
      height: 300px;
    }
  }

  .third-row {
    .MuiCard-root {
      height: 410px;
      overflow-y: auto;
    }
  }
  .medium-row {
    .MuiCard-root {
      height: 430px;
      overflow-y: auto;
    }
  }
  .mdrFirstRow{
    .MuiCard-root {
      height: 100% !important;
    }
  }

  .sensitiveGroupWidget {
    .MuiCard-root {
      height: 410px;
    }
  }
  

  .mdrSecondRow{
    .MuiCard-root {
      height: 100%;
    }
  }
  .mdrThirdRow{
    .MuiCard-root {
      // margin-top:-5rem;
      min-height: 38rem;
      max-height: 870px;
    }
  }

  .mdrIncidentFirstRow{
    .MuiCard-root {
     height:11rem;

    }
  }
  .mdrIncidentSecondRow{
    .MuiCard-root {
      min-height: 28rem;
      max-height: 35rem;
      margin-bottom: 2rem;
    }
  }
  .mdrIncidentThirdRow{
  .MuiCard-root {
    min-height: 24rem;

  }
}
.mdrIncidentFourthRow{
  .MuiCard-root {
    min-height: 24rem;
    margin-bottom: 2rem;
  }
}


  .mdrEndPointsFirstRow{
    .MuiCard-root {
      // min-height:20rem;
      height: 11rem
    }
  }
  .mdrEndPointsThirdRow{
    .MuiCard-root {
      min-height: 400px;
      margin-bottom: 2rem;
    }

  }
  .mdrAutheticationFirstRow{
    .MuiCard-root {
       min-height:11rem;
       height:11rem;
     }
  }
  .mdrSecondRowAuthentication{
    .MuiCard-root {
      min-height:15rem;
      height: 100%;
      // margin-bottom: 1rem;
    }
  }
  .mdrThirdRowAuthentication{
    .MuiCard-root {
      min-height:15rem;
      height: 90%;
      margin-bottom: 1rem;
    }
  }
  
  
  .mdrEndPointsSecondRow{
    .MuiCard-root {
      min-height:12rem;
      height: 100%;
    }
  }

  .mdrPatchMonitoringFirstRow{
    .MuiCard-root {
      height:11rem;
 
    }
  }
  .mdrPatchMonitoringSecondRow{
    .MuiCard-root {
      min-height: 25rem;
      height: 100%;
    }
  }

  .mdrPatchMonitoringThirdRow{
    .MuiCard-root {
      min-height:20rem;
      height: 100%;
    }
  }

    .MuiPopover-paper {
      height: 50px !important;
    }
  

  .donut-legends {
    max-height: 120px;
    // overflow-y: scroll;
    box-shadow: none;
    border-bottom-color: #ccc;

    &:not( :hover ) {
      overflow-y: hidden;
    }

    &:hover {
      overflow-y: scroll;
    }

    .content {
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    tbody {
      td {
        padding: 5px;
      }
    }
  }
}
.widget-header2{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.span-total-count{
  font-size: 17px;
  font-weight: bold;
}
.view-details {
  text-decoration: none;
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  color: #706F7D;
  background: #FFFFFF;
  border: 0.2px solid #9F9F9F;
  box-shadow: 0px 5px 8px -3px rgba(153, 153, 155, 0.5);
  border-radius: 4px;
  padding: 5px;
}
.asset-legend-container{
  margin-top: 5%;
}
.asset-legend{
  display: flex;
  align-items: center;
  padding: 8% 0;
}
.asset-legend-img-count{
  flex:50%;
  display: flex;
  align-items: center;
}
.asset-legend-icon{
  margin-left: 5%;
}
.asset-legend-count {
  margin-left: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
}
.asset-legend-text {
  flex:50%;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 0px 10px;
}
.separatorStyle {
  border-left: none;
  border-right: none;
  border-top: 1px solid #ccc;
  height: 1px
}
.mdrRenderSingleValue{
  height: 24px;
  // width: 72px;
  color: #323232;
  font-family: Nunito-Regular;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}
.separatorStyle {
  border-left: "none";
  border-right: "none";
  border-top: "1px solid #ccc";
  height: "1px";
}
.sensitiveLevelWidget{
  display: flex;
  flex-direction: column;
  .heatMapLegend{
    padding-left: 10px;
  }
  .heatMapLegenedContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin-top: 5%;
  }
  .heatMapLegendCount{
    display: flex;
    align-items: center;
  }
  .indicator-container {
    display: flex;
    .bar-background {
      width: 80%;
      margin-right: 20px;
      background-color: #F1F1F1;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
      border-radius: 4px;
    }
  }
  .bar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 19px;
    border-radius: 4px;
    &.high {
      background: #FFC2CF;
      border: 1px #FF2251 solid;
    }
    &.medium {
      background: #FFC596;
      border: 1px #FF8421 solid;
    }
    &.low {
      background: #DEEEFD;
      border: 1px #081981 solid;
    }
    &.nonClassified {
      background: #EAC8FF;
      border: 1px #5D0A90 solid;
    }
    &.multipleCategory{
      background: #EAC8FF;
      color:#081981;
    }
    &.no-border {
      border: none;
    }
  }
  .view-details-header{
    display: flex;
    justify-content: flex-end;
  }
  .sensitivity-file-count{
    padding: 10px;
  }
}

.totalGroupsWidget{
  .group-container{
    margin-top: 2%;
    font-size: 13px;
  }
  .bar-background{
    width: 100%;
  }
  .bar {
    height: 20px;
    margin-bottom: 2px;
    &.devOps {
      background: #081981;
      border: 1px #081981 solid;
    }
    &.engineering {
      background: #1A2A8C;
      border: 1px #1A2A8C solid;
    }
    &.it {
      background: #1831C2;
      border: 1px #1831C2 solid;
    }
    &.sales {
      background: #2B46E8;
      border: 1px #2B46E8 solid;
    }
    &.finance{
      background: #4B62EC;
      border:#4B62EC;
    }
  }
  .view-details-header{
    display: flex;
    justify-content: space-between;
  }
  .group-percentage{
    margin-left: 2%;
  }
}
.dashboard-selector{
  width: 30%;
  text-align: center;
  margin-bottom: 12px;
  .user-type-dropdown{
    min-width: 150px;
    max-width: 400px;
    font-size: 14px;
  }
}
.donut-container{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.public-file-icon{
  width: 30px;
}
.out-of-container {
  display: flex;
  align-items: center;
  span {
    margin: 0.2em;
  }
}