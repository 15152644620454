.custom-data-grid-main {
  height: 320px;
  width: 100%;
  &.report {
    margin-left: 20px;
    width: 94%;
  }
  .MuiDataGrid-root,
  .MuiDataGrid-columnHeader {
    border: none;
  }

  .MuiDataGrid-cell--selected {
    outline: none !important;
  }

  .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-row:hover {
    cursor: pointer;
  }
    &.location {
  
      /* Disable hover highlight on rows */
      .MuiDataGrid-row:hover {
        background-color: transparent !important;
      }
  
      /* Disable pointer cursor on row hover */
      .MuiDataGrid-row:hover {
        cursor: default !important;
      }
  
    }
}

.custom-data-grid {
  .MuiDataGrid-withBorderColor {
    border: none;
  }

  .MuiDataGrid-cell {
    border-bottom: none !important;
    padding: 5px;
  }

  .MuiDataGrid-row {
    padding: 2px;
    background: #FDFDFF;
    border: 1px solid #DEDEDE;
    box-shadow: 0px 0px 16px rgba(128, 128, 128, 0.15);
    border-radius: 4px;
  }

  .MuiDataGrid-root::-webkit-scrollbar {
    width: 10px;
  }
}

.oneDriveCategoryContainer {
  display: flex;
  position: relative;
}

.oneDriveCategoryIcons {
  display: flex;
  position: relative;
  gap: 13px;
  width: 150px;
  max-width: 150px;
  overflow: hidden;
  &.dataOwner {
    width: 120px;
  }
}

.oneDriveIconImage {
  min-width: 25px;
}


.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  &.oneDrive {
    background: #323232;
  }
  &.ntfs {
    background: #9eaaf4;
    margin-bottom: 6px;
  }
}
.circleText {
  color: white;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  font-size: 12px;
  padding: 2px;
}
.fs-file-list-container {
  margin-left: 200px;
  height: 100vh;
  .custom-data-grid-main {
    height: 100%;
  }
  .main-heatmap-content{
    height: 100%;
  }
}
.file-list{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 20px;
  margin-right: 20px;
}
.file-list-items{
  display: flex;
  gap: 1rem;
  .inspect-sort{
    margin-left: 0;
  }
}

.categoryIcons {
  display: flex;
  gap: 13px;
}

.categorySelector {
  width: 250px;
}